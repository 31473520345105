<template>
  <div class="form--group" :class="{ 'has-error': validation.hasError('unitNumber') }">
    <div class="left--col">
      <span>{{ $t('addListing.penomoranunit') }}</span>
    </div>
    <div class="right--col">
      <div class="row my-0">
        <div class="col-6 py-0" style="padding-right: 8px">
          <v-text-field
            :label="$t('addListing.placeholder.unitNumber')"
            outlined
            :hide-details="true"
            v-model="unitNumber"
            name="unit_name"
            :class="validation.hasError('unitNumber') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('unitNumber')">{{
            validation.firstError('unitNumber')
          }}</span>
        </div>
        <div class="col-6 py-0" style="padding-left: 8px" v-show="blockNumberRule.allowed">
          <v-text-field
            :label="$t('addListing.placeholder.blockNumber')"
            outlined
            :hide-details="true"
            v-model="blockNumber"
            name="block_number"
            :class="validation.hasError('blockNumber') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('blockNumber')">{{
            validation.firstError('blockNumber')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapGetters } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'unit-numbering-section',
  mixins: [HelperMixin],
  computed: {
    ...mapGetters({
      blockNumberRule: 'listingForm/sect2/detail/blockNumberRule',
    }),
    unitNumber: {
      get() {
        return this.$store.state.listingForm.sect1.location.unitNumber;
      },
      set(value) {
        return this.$store.commit(
          'listingForm/sect1/location/SET_UNIT_NUMBER',
          this.cleanInput(value),
        );
      },
    },
    blockNumber: {
      get() {
        return this.$store.state.listingForm.sect1.location.blockNumber;
      },
      set(value) {
        return this.$store.commit(
          'listingForm/sect1/location/SET_BLOCK_NUMBER',
          this.cleanInput(value),
        );
      },
    },
  },
  methods: {
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    blockNumber: function (value) {
      if (!this.blockNumberRule.allowed || !this.blockNumberRule.required) {
        return Validator.value(value).maxLength(
          20,
          this.$i18n.t('errors.address.max', { maxChar: 20 }),
        );
      } else {
        return Validator.value(value)
          .required(this.$i18n.t('errors.unit.required'))
          .maxLength(20, this.$i18n.t('errors.address.max', { maxChar: 20 }));
      }
    },
    unitNumber: function (value) {
      return Validator.value(value).maxLength(
        20,
        this.$i18n.t('errors.address.max', { maxChar: 20 }),
      );
    },
  },
};
</script>

<style scoped></style>
